import axios, { RawAxiosRequestHeaders, AxiosRequestHeaders } from 'axios';
import getApplicationApiStage from 'utils/getApplicationApiStage';
import { v4 as uuidv4 } from 'uuid';
import { handleRefreshTokenV2 } from '@teamfabric/copilot-utilities';
import { unleash } from 'hooks/useFeatureFlags';
import { FEATURE_FLAGS } from 'hooks/useFeatureFlags/utils';
import { parseUnleashVariant } from 'hooks/useFeatureFlag';
import { getTenantId } from 'utils';

let retry = 0;
const MAX_RETRY = 3;

const getV3Headers = () => {
  return {
    'X-Fabric-Channel-Id': '12',
    'X-Fabric-Request-Id': `copilot-oms-${Date.now()}`,
    'Authorization':
      typeof window !== 'undefined'
        ? sessionStorage.getItem('accessToken')
        : '',
  } as RawAxiosRequestHeaders;
};

interface BaseUrlStrategy {
  (stage: string, type: string): string;
}

const getV3ApiBaseUrl: BaseUrlStrategy = (stage: string, type: string) => {
  return `https://${stage}.oms.fabric.inc/api/v3/${type}`;
};

const ServicesUrls = {
  'locations': process.env.OMS_API_V3_LOCATIONS_BASE_URL,
  'audits': process.env.OMS_API_V3_AUDITS_URL,
  'opal': process.env.OPAL_V3_BASE_URL,
  'location-outages': process.env.OMS_API_V3_LOCATION_OUTAGES_BASE_URL,
  'location-capacity': process.env.OMS_API_V3_LOCATION_CAPACITY_URL,
  'inventories': process.env.OMS_API_V3_INVENTORIES_BASE_URL,
  'inventory-networks': process.env.OMS_API_V3_NETWORKS_BASE_URL,
  'shipping-methods': process.env.OMS_API_V3_SHIPPING_URL,
  'configurations': process.env.OMS_API_V3_CONFIGURATIONS_URL,
  'inventory-imports': process.env.OMS_API_V3_INVENTORY_IMPORTS_URL,
  'inventory-counters': process.env.OMS_API_V3_INVENTORY_COUNTERS_URL,
  'oms-imports': process.env.OMS_API_V3_IMPORTS_URL,
  'oms-exports': process.env.OMS_API_V3_EXPORTS_URL,
  'copilot': process.env.OMS_API_V3_COPILOT_URL,
  'alerts': process.env.OMS_API_V3_ALERTS_URL,
};

const servicesUrlsMap = {
  locations: { id: '/locations', urlKey: 'OMS_API_V3_LOCATIONS_BASE_URL' },
  audits: { id: '/audits', urlKey: 'OMS_API_V3_AUDITS_URL' },
  opal: { id: '/', urlKey: 'OPAL_V3_BASE_URL' },
  locationOutages: {
    id: '/location-outages',
    urlKey: 'OMS_API_V3_LOCATION_OUTAGES_BASE_URL',
  },
  locationCapacity: {
    id: '/location-capacity',
    urlKey: 'OMS_API_V3_LOCATION_CAPACITY_URL',
  },
  inventories: {
    id: '/inventories',
    urlKey: 'OMS_API_V3_INVENTORIES_BASE_URL',
  },
  network: {
    id: '/inventory-networks',
    urlKey: 'OMS_API_V3_NETWORKS_BASE_URL',
  },
  shippingMethods: {
    id: '/shipping-methods',
    urlKey: 'OMS_API_V3_SHIPPING_URL',
  },
  configurations: {
    id: '/configurations',
    urlKey: 'OMS_API_V3_CONFIGURATIONS_URL',
  },
  inventoryImports: {
    id: '/inventory-imports',
    urlKey: 'OMS_API_V3_INVENTORY_IMPORTS_URL',
  },
  inventoryCounters: {
    id: '/inventory-counters',
    urlKey: 'OMS_API_V3_INVENTORY_COUNTERS_URL',
  },
  omsImports: { id: '/oms-imports', urlKey: 'OMS_API_V3_IMPORTS_URL' },
  omsExports: { id: '/oms-exports', urlKey: 'OMS_API_V3_EXPORTS_URL' },
  copilot: { id: '/copilot', urlKey: 'OMS_API_V3_COPILOT_URL' },
  alerts: { id: '/alerts', urlKey: 'OMS_API_V3_ALERTS_URL' },
  default: {
    urlKey: 'OMS_API_V3_DEFAULT_BASE_URL',
  },
};

const normalizeURL = URL => (URL.at(-1) === '/' ? URL.slice(0, -1) : URL);

const getAPIBaseURLFromEnvironment: BaseUrlStrategy = (
  stage: string,
  type: string
) => {
  const specificURL = ServicesUrls[type];

  if (specificURL) {
    return normalizeURL(specificURL);
  }

  if (!process.env.OMS_API_V3_DEFAULT_BASE_URL) return null;

  const baseURL = process.env.OMS_API_V3_DEFAULT_BASE_URL;
  return `${normalizeURL(baseURL)}/${type}`;
};

const getAPIBaseFirstFromEnvFallbackToPrevious: BaseUrlStrategy = (
  stage: string,
  type: string
) => {
  return (
    getAPIBaseURLFromEnvironment(stage, type) ?? getV3ApiBaseUrl(stage, type)
  );
};

// Axios Instance Version:V3
const getAxiosInstanceV3 = (
  type: string,
  getHeaders: () => RawAxiosRequestHeaders = getV3Headers,
  baseUrlStrategy: BaseUrlStrategy = getAPIBaseFirstFromEnvFallbackToPrevious
) => {
  const stage = getApplicationApiStage();
  const client = axios.create({
    baseURL: baseUrlStrategy(stage, type),
    responseType: 'json',
    headers: getHeaders(),
  });
  client.interceptors.request.use(request => {
    const token = sessionStorage.getItem('accessToken');
    const tenantId = getTenantId({ axios });
    request.headers = {
      ...getHeaders(),
      ...request.headers,
      'Authorization': token,
      'X-Fabric-Tenant-Id': tenantId,
      'X-Fabric-Request-Id': `${
        request.headers['X-Fabric-Request-Id']
      }-${uuidv4()}`,
    } as unknown as AxiosRequestHeaders;

    return request;
  });
  return client;
};

const clientV3 = {
  locations: getAxiosInstanceV3('locations'),
  audits: getAxiosInstanceV3('audits'),
  locationOutages: getAxiosInstanceV3('location-outages'),
  locationCapacity: getAxiosInstanceV3('location-capacity'),
  inventories: getAxiosInstanceV3('inventories'),
  network: getAxiosInstanceV3('inventory-networks'),
  shippingMethods: getAxiosInstanceV3('shipping-methods'),
  configurations: getAxiosInstanceV3('configurations'),
  inventoryImports: getAxiosInstanceV3('inventory-imports'),
  inventoryCounters: getAxiosInstanceV3('inventory-counters'),
  omsImports: getAxiosInstanceV3('oms-imports'),
  omsExports: getAxiosInstanceV3('oms-exports'),
  copilot: getAxiosInstanceV3('copilot'),
  opal: getAxiosInstanceV3('opal'),
  alerts: getAxiosInstanceV3('alerts'),
};

export const handleErrorResponse = async (error, domain) => {
  if (retry >= MAX_RETRY) {
    retry = 0;
    return Promise.reject(error);
  }

  if (error?.response?.status === 401 || error?.response?.status === 403) {
    try {
      retry = retry + 1;
      const data = await handleRefreshTokenV2();

      if (data === 'success') {
        const accessToken = sessionStorage.getItem('accessToken');

        const tenantId = getTenantId({ axios });

        const request = {
          ...error.config,
          headers: {
            ...error.config.headers,
            'Authorization': accessToken,
            'X-Fabric-Request-Id': `${
              error.config.headers['X-Fabric-Request-Id']
            }-${uuidv4()}`,
            'X-Fabric-Tenant-Id': tenantId,
          },
        };
        return clientV3[domain].request(request);
      } else {
        return Promise.reject(error);
      }
    } catch (error) {
      window.location.href = `${window.location.origin}/auth/v2/login`;
    }
  }
  if (error?.response?.status === 500) {
    return error.response;
  }
  return Promise.reject(error);
};

Object.keys(clientV3).forEach(domain => {
  clientV3[domain].interceptors.response.use(
    response => {
      return response;
    },
    error => {
      return handleErrorResponse(error, domain);
    }
  );
});

const updateBaseUrlsFromFlags = (flags: any) => {
  Object.keys(clientV3).forEach(domain => {
    const url =
      flags?.[servicesUrlsMap[domain].urlKey] ||
      (flags?.[servicesUrlsMap.default.urlKey] &&
        flags?.[servicesUrlsMap.default.urlKey] + servicesUrlsMap[domain].id);

    clientV3[domain].defaults.baseURL =
      url ?? clientV3[domain].defaults.baseURL;
  });
};

export const updateV3Client = () => {
  const variant = unleash.getVariant(FEATURE_FLAGS.MT_SERVICE);
  const urls = parseUnleashVariant({ variant });
  const tenantId = getTenantId({});

  const tenantUrls = {
    ...urls?.value?.['default'],
    ...(urls?.value?.[tenantId] || {}),
  };
  updateBaseUrlsFromFlags(tenantUrls); // Update base URLs based on flags
};

export default clientV3;
