import { format } from 'date-fns';
import { isNil, isObjectLike } from 'lodash';
import { StyledTitleItem, StyledSubTitleItem } from 'styles';

export const downloadFile = (url, fileName) => {
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const isValidJson = value => {
  try {
    JSON.parse(value);
    return true;
  } catch (e) {
    return false;
  }
};

export const formatISODate = (date, futureDate = false) => {
  const lastUpdate = new Date(date);
  const now = new Date();

  const timeDiff = now.getTime() - lastUpdate.getTime();
  const minutesDiff = Math.floor(timeDiff / 60000); // Convert milliseconds to minutes

  if (minutesDiff < 1 && !futureDate) {
    return <div>Just now</div>;
  } else if (minutesDiff < 60 && !futureDate) {
    return <div>{minutesDiff} minutes ago</div>;
  } else if (minutesDiff < 1440 && !futureDate) {
    return <div>Today</div>;
  } else if (minutesDiff < 2880 && !futureDate) {
    return <div>Yesterday</div>;
  } else {
    return (
      <div>
        <StyledTitleItem>{date ? format(date, 'PPP') : ''}</StyledTitleItem>
        <StyledSubTitleItem>{date ? format(date, 'p') : ''}</StyledSubTitleItem>
      </div>
    );
  }
};

export const normalize = obj => {
  if (!isObjectLike(obj)) return {};
  return Object.fromEntries(
    Object.entries(obj).filter(([key, value]) => !isNil(value))
  );
};

export const formatBadgeLabel = (inputString: string) => {
  if (inputString && inputString?.length > 0) {
    const parts = inputString.toLowerCase().split('_');

    const formattedParts = parts.map(
      part => part.charAt(0).toUpperCase() + part.slice(1)
    );

    const formattedString = formattedParts.join(' ');

    return formattedString;
  }
  return '';
};

export const getTenantId = ({ axios }: { axios?: any }) => {
  const tenantId = window.localStorage.getItem('isLoggedInWithIdV2')
    ? window.sessionStorage.getItem('accountId')
    : window.sessionStorage.getItem('account');

  if (axios && !tenantId) {
    window.location.href = `${window.location.origin}/auth/v2/login`;
    throw new Error(
      new axios.Cancel('Tenant ID is missing. Redirecting to login.')
    );
  }

  return tenantId;
};

export const transformFilters = ({ filters, prefix }) => {
  const results = {};

  filters?.length > 0 &&
    filters.forEach(({ field, value, values, condition }) => {
      const fieldName = field?.replace(prefix, '');

      if (!results[fieldName]) {
        results[fieldName] = {};
      }
      if (['IN', 'NIN'].includes(condition)) {
        results[fieldName][condition?.toLowerCase()] = values;
      } else {
        results[fieldName][condition?.toLowerCase()] = value;
      }
    });

  return results;
};

export const formatFilters = ({ filters, prefix }) => {
  const filtersMap = {
    networkCodes: 'networkCode',
    channels: 'channelId',
    skus: 'sku',
  };
  return Object.keys(filters)?.map(filter => ({
    field: `${prefix}${filtersMap[filter] ?? filter}`,
    condition: 'IN',
    values: filters[filter],
  }));
};
