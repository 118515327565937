const APPLICATION_STAGE_REMAP = {
  'live': 'prod01',
  'greatwall-production-dr': 'prod01',
} as const;

const getApplicationStage = () => {
  try {
    const originUrl = new URL(window.location.origin);
    const stage = originUrl.hostname?.split('.')[0];
    return APPLICATION_STAGE_REMAP[stage] ?? stage;
  } catch (e) {
    return 'dev02';
  }
};

export default getApplicationStage;
