import { createReducer } from 'store/utils';
import { LandingFilters } from 'types';
import { Dispatch } from 'redux';

export enum ActionType {
  SET_FILTER = 'set_filter',
}

type UpdateFilterPayload = {
  service: 'inventory' | 'order';
  key: string;
  newValue: Array<string | number | boolean>;
};

interface SetFilterAction {
  type: ActionType.SET_FILTER;
  payload: UpdateFilterPayload;
}

const initialState = {
  inventory: { networkCodes: [] },
} as LandingFilters;

const setFilter = (state, action) => {
  const { service, key, newValue } = action.payload;
  return {
    ...state,
    [service]: { ...state[service], [key]: newValue },
  };
};

export const setLandingFilter =
  (payload: UpdateFilterPayload) => (dispatch: Dispatch<SetFilterAction>) => {
    return dispatch({ type: ActionType.SET_FILTER, payload });
  };

export default createReducer(initialState, {
  [ActionType.SET_FILTER]: setFilter,
});
