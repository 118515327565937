import DS4Switch from './DS4Switch';
import { FeatureFlagsProvider } from 'hooks/useFeatureFlags/context';

const Root = () => {
  return (
    <FeatureFlagsProvider>
      <DS4Switch />
    </FeatureFlagsProvider>
  );
};
export default Root;
