import { useContext, useMemo } from 'react';
import { FeatureFlagsContext, unleash } from 'hooks/useFeatureFlags';
import { IVariant } from 'unleash-proxy-client';
import { safeJsonParse } from 'utils/safeJsonParse';
import { UnleashFlag } from 'types';

export const parseUnleashVariant = ({ variant }: { variant: IVariant }) => {
  if (!variant) {
    return {};
  }

  const { name, payload } = variant;
  const { type, value } = payload || {};
  if (type === 'json') {
    return { name, value: safeJsonParse(value) };
  }
  return { name, value };
};

const useFeatureFlag = (flagName: string) => {
  const { isFetching } = useContext(FeatureFlagsContext);

  const active = unleash.isEnabled(flagName);
  const variant = unleash.getVariant(flagName);

  return useMemo(
    () =>
      ({
        active,
        isFetching,
        variant: parseUnleashVariant({ variant }),
      } as UnleashFlag),
    [active, isFetching, variant]
  );
};

export default useFeatureFlag;
