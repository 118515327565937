export const APP = 'inventory';
export const DEFAULT_PAGE = 1;
export const DEFAULT_LIMIT = 10;
export const DEFAULT_SORT = 'location.updatedAt';
export const DEFAULT_ORDER = '-';
export const MAX_POLLING_BACKOFF = 300000;
export const POLLING_BACKOFF_EXPONENT = 2;

export enum PollingStatusEnum {
  FINISHED = 'FINISHED',
  FILE_ERROR = 'FILE_ERROR',
  SYSTEM_ERROR = 'SYSTEM_ERROR',
  PROCESSING = 'PROCESSING',
  INITIATED = 'INITIATED',
  COMPLETED = 'COMPLETED',
}

enum ClosureStatusEnum {
  CREATED = 'CREATED',
  STARTED = 'STARTED',
  ENDED = 'ENDED',
  CANCELLED = 'CANCELLED',
}

const ClosureBadgeStatusValue = {
  [ClosureStatusEnum.CREATED]: 'success',
  [ClosureStatusEnum.STARTED]: 'success',
  [ClosureStatusEnum.ENDED]: 'info',
  [ClosureStatusEnum.CANCELLED]: 'error',
};

export const BOOLEAN_DROPDOWN_OPTIONS = [
  {
    label: 'Yes',
    id: 'true',
    value: true,
  },
  {
    label: 'No',
    id: 'false',
    value: false,
  },
];

export const DEFAULT_CHANNELS = ['12', '13'];

export enum SEARCH_TYPE {
  WILDCARD = 'WILDCARD',
  EXACT = 'EXACT',
}

export const statusFilters = [
  { id: 'ACTIVE', label: 'Active', value: 'Active', disabled: false },
  { id: 'INACTIVE', label: 'Inactive', value: 'Inactive', disabled: false },
];

export const getClosureStatusBadgeStatusValue = (status: ClosureStatusEnum) => {
  return ClosureBadgeStatusValue[status] ?? 'default';
};
