import { createContext, PropsWithChildren } from 'react';
import useFeatureFlags from 'hooks/useFeatureFlags';

export const FeatureFlagsContext = createContext<{
  isFetching: boolean;
}>({
  isFetching: false,
});

export const FeatureFlagsProvider = ({ children }: PropsWithChildren<any>) => {
  const featureFlagContextValue = useFeatureFlags();
  return (
    <FeatureFlagsContext.Provider value={featureFlagContextValue}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};
